import React from 'react';
import { useTranslation } from 'react-i18next';

import { Snackbar, useTheme, Typography } from '@mui/material';

import Iconography from '../Iconography';

import { StyledLine, StyledSnackbarWrapper, StyledContentContainer, StyledErrorIcon, StyledCloseIcon } from './styled';

interface ErrorSnackbarProps {
  open: boolean;
  handleClose: () => void;
}

const ErrorSnackbar = ({ open, handleClose }: ErrorSnackbarProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={6000}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
    >
      <StyledSnackbarWrapper>
        <StyledErrorIcon>
          <Iconography iconName="error-icon" htmlColor={theme.palette.error.main} />
        </StyledErrorIcon>
        <StyledLine />
        <StyledContentContainer>
          <Typography variant="body1" mb="5px">
            {t('landingPage.snackbar.title')}
          </Typography>
          <Typography variant="body1" fontWeight="400">
            {t('landingPage.snackbar.content')}
          </Typography>
        </StyledContentContainer>
        <StyledCloseIcon onClick={handleClose}>
          <Iconography iconName="close-icon" htmlColor={theme.palette.success.light} />
        </StyledCloseIcon>
      </StyledSnackbarWrapper>
    </Snackbar>
  );
};

export default ErrorSnackbar;
