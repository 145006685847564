import { Box, experimentalStyled as styled } from '@mui/material';

export const StyledSnackbarWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  maxWidth: '296px',
  width: '100%',
  height: '71px',
  padding: '12px 12px 12px 21px',
  display: 'flex',
  alignItems: 'center',
  boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',
  borderRadius: '5px',
}));

export const StyledLine = styled(Box)(({ theme }) => ({
  height: '41px',
  width: '1px',
  backgroundColor: theme.palette.info.contrastText,
  margin: '0 15px',
}));

export const StyledContentContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledErrorIcon = styled(Box)(() => ({
  '& svg': {
    width: '18px',
    height: '18px',
  },
}));

export const StyledCloseIcon = styled(Box)(() => ({
  height: '100%',
  paddingLeft: '12px',
  cursor: 'pointer',

  '& svg': {
    width: '10px',
    height: '10px',
  },
}));
