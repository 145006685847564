import { Box, experimentalStyled as styled, Typography } from '@mui/material';

import EmailSuccessful from 'src/components/Landing/icons/EmailSuccessful.png';

export const StyledPageWrapper = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.background.default,
}));

export const StyledPositionBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingTop: '40px',
  margin: '0 24px',

  [theme.breakpoints.down('mobile')]: {
    margin: '0 16px',
  },
}));

export const StyledConfirmationContainer = styled(Box)(() => ({
  '& .MuiSvgIcon-root': {
    width: '16px',
  },
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  width: '100%',
  maxWidth: '384px',
  marginBottom: '24px',

  [theme.breakpoints.down('mobile')]: {
    fontSize: '18px',
    lineHeight: '26px',
    padding: '0 16px',
  },
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  width: '100%',
  maxWidth: '547px',

  '& .MuiLink-root:hover': {
    color: theme.palette.info.light,
  },

  [theme.breakpoints.down('mobile')]: {
    fontSize: '14px',
    lineHeight: '22px',
    padding: '0 50px',
  },
}));

export const StyledContentWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
}));

export const StyledMessageForm = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '384px',
  marginTop: '40px',

  [theme.breakpoints.down('mobile')]: {
    padding: '0 16px',
  },
}));

export const StyledFormWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  margin: 34px 0 180px;
`;

export const StyledBackButton = styled(Box)(({ theme }) => ({
  display: 'flex',

  h6: {
    fontWeight: 400,
    marginLeft: '14px',
  },

  '&:hover': {
    cursor: 'pointer',
  },

  [theme.breakpoints.down('mobile')]: {
    fontSize: '14px',
    lineHeight: '22px',
  },
}));

export const StyledIcon = styled(Box)(() => ({
  background: `url(${EmailSuccessful}) no-repeat`,
  backgroundSize: 'contain',
  width: '117px',
  height: '135px',
}));

export const StyledButtonWrapper = styled(Box)(({ theme }) => ({
  padding: '77px 24px 0',
  maxWidth: '385px',
  width: '100%',

  [theme.breakpoints.down('mobile')]: {
    padding: '77px 16px 0',
  },
}));
