import { graphql } from 'gatsby';

import ConfirmationPage from 'src/components/ConfirmationPage/ConfirmationPage';
import withGlobalStyles from 'src/components/GlobalStyleHOC';

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default withGlobalStyles(ConfirmationPage);
