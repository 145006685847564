import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { useLazyQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Link, Typography } from '@mui/material';
import { navigate } from 'gatsby';

import Button from 'src/components/common/Button/Button';
import ErrorSnackbar from 'src/components/common/ErrorSnackbar/ErrorSnackbar';
import Iconography from 'src/components/common/Iconography';
import MultilineInput from 'src/components/Forms/MultilineInput';
import { descriptionFormSchema } from 'src/components/Forms/validationSchemas';
import Header from 'src/components/Header/Header';
import Footer from 'src/components/Landing/Footer/Footer';
import { StyledWrapperBlock, StyledInformationText } from 'src/components/Landing/styled';
import { LINK_TO_SURVEY, Section } from 'src/constants';
import { CREATE_ACCOUNT_INQUIRY } from 'src/queries';
import { theme } from 'src/theme';

import Seo from '../SEO/SEO';

import {
  StyledPositionBlock,
  StyledMessageForm,
  StyledTitle,
  StyledText,
  StyledContentWrapper,
  StyledPageWrapper,
  StyledConfirmationContainer,
  StyledFormWrapper,
  StyledBackButton,
  StyledIcon,
  StyledButtonWrapper,
} from './styled';

interface RegisterUserProps {
  description?: string;
}

const ConfirmationPage = () => {
  const { t } = useTranslation();

  const formProps = useForm<RegisterUserProps>({
    mode: 'onChange',
    resolver: yupResolver(descriptionFormSchema),
  });

  const [openErrorSnackbar, setOpenErrorSnackbar] = useState<boolean>(false);

  const { handleSubmit } = formProps;

  const [sendSupportEmail, { loading, data }] = useLazyQuery(CREATE_ACCOUNT_INQUIRY, {
    fetchPolicy: 'network-only',
    onError: () => setOpenErrorSnackbar(true),
  });

  const handleRegisterUser = handleSubmit(async ({ description }: RegisterUserProps) => {
    const email = localStorage.getItem('_email');
    const name = localStorage.getItem('_name');

    await sendSupportEmail({
      variables: {
        data: { email, name, description },
      },
    });
  });

  const handleClickBackButton = () => {
    navigate(`/#${Section.Top}`);
    localStorage.clear();
  };

  const handleCloseSnackbar = () => {
    setOpenErrorSnackbar(false);
  };

  return (
    <>
      <Seo title="Confirmation" />

      <StyledPageWrapper>
        <Header showOnlyHeaderPanel />

        <ErrorSnackbar open={openErrorSnackbar} handleClose={handleCloseSnackbar} />

        <Box m="0 auto" width="100%" maxWidth="1248px">
          <StyledPositionBlock>
            <StyledConfirmationContainer>
              <StyledBackButton onClick={handleClickBackButton}>
                <Iconography iconName="arrow-back" htmlColor={theme.palette.text.primary} />

                <Typography variant="subtitle1">{t('confirmationPage.backToThePage')}</Typography>
              </StyledBackButton>
            </StyledConfirmationContainer>
          </StyledPositionBlock>
        </Box>

        <StyledFormWrapper>
          <StyledConfirmationContainer>
            <FormProvider {...formProps}>
              <form onSubmit={handleRegisterUser}>
                <StyledContentWrapper>
                  {data?.createAccountInquiry && !loading ? (
                    <StyledWrapperBlock maxWidth="384px">
                      <StyledIcon />

                      <StyledInformationText variant="h4">
                        {t('landingPage.stayInTouchBlock.landingForm.successMessage')}
                      </StyledInformationText>

                      <StyledButtonWrapper>
                        <Button onClick={handleClickBackButton}>
                          {t('landingPage.stayInTouchBlock.landingForm.successButton')}
                        </Button>
                      </StyledButtonWrapper>
                    </StyledWrapperBlock>
                  ) : (
                    <>
                      <StyledTitle variant="h3">{t('confirmationPage.title')}</StyledTitle>

                      <StyledText variant="subtitle1" fontWeight="400">
                        <Trans i18nKey="confirmationPage.content">
                          We appreciate your participation in our
                          <Link href={LINK_TO_SURVEY} target="_blank" rel="noopener noreferrer" color="inherit">
                            survey
                          </Link>
                          and sharing valuable insights. If you&apos;ve already taken it, thank you for your time! If
                          you haven&apos;t had a chance to do it yet, why not make it your next coffee break activity?
                        </Trans>
                      </StyledText>

                      <StyledText mt="12px" variant="subtitle1" fontWeight="400">
                        {t('confirmationPage.content2')}
                      </StyledText>

                      <StyledText mt="12px" variant="subtitle1" fontWeight="400">
                        {t('confirmationPage.content3')}
                      </StyledText>

                      <StyledMessageForm>
                        <MultilineInput name="description" label={t('confirmationPage.label')} />
                        <Box mt="12px">
                          <Button type="submit" loading={loading}>
                            {t('confirmationPage.button')}
                          </Button>
                        </Box>
                      </StyledMessageForm>
                    </>
                  )}
                </StyledContentWrapper>
              </form>
            </FormProvider>
          </StyledConfirmationContainer>
        </StyledFormWrapper>

        <Footer />
      </StyledPageWrapper>
    </>
  );
};

export default ConfirmationPage;
